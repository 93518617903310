import React from 'react';
import Snake from "./Snake";
import data from "./assets/radiochannels.json";
import harlow from "./assets/harlow.png";
import logo from "./assets/logo.png";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {sesSeviyesi: 100,snakeDivHeight:"0px",listItems:null,muzikCalar:null,suanCaliyor:false,currentSeek:0,resimler:null,nowHls:null};
        this.oynat = this.oynat.bind(this);
        this.kanaldegistir = this.kanaldegistir.bind(this);
        this.pressedPlay = this.pressedPlay.bind(this);
        this.pressedPause = this.pressedPause.bind(this);
        this.pressedMute = this.pressedMute.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.setState({muzikCalar:document.getElementById("muzikCalar1")});
        this.kanallariayarla();
        this.setClock();
        const yeniResimler = this.importAll(require.context('./assets/kanalresimleri', false, /\.(png|jpe?g|webp)$/));
        this.setState({resimler:yeniResimler});
        //document.getElementById("channelImg").style.backgroundImage = "url("+yeniResimler['superfm.webp']+")";
        document.getElementById("channelImg").style.backgroundImage = "url("+logo+")";
    }
    handleChange(event) {
        this.setState({sesSeviyesi: event.target.value});
        let varx = event.target.value > 100 ? 100 : event.target.value
        let volume = varx / 100;
        if (isNaN(volume)) {
            volume=0.1;
        }
        this.state.muzikCalar.volume = parseFloat(volume.toString());
    }

    importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    setClock() {
        if(this.state.suanCaliyor){
            this.setState({ currentSeek: Math.floor(this.state.muzikCalar.currentTime) });
        }
        setTimeout(
            () => this.setClock(),
            1000
        );
    }
    kanallariayarla() {
        const totalArray = [];
        const kacSatirVar = [];
        for(let i=0;i<data.channels.length;i+=4) {
            kacSatirVar.push(i);
        }
        const yeniSatir = kacSatirVar.map((eleman,index) =>
            <tr key={index}>
                {data.channels.slice(eleman, eleman+4).map((kanal,index2) => <td onClick={this.kanaldegistir} value={kanal.channelUrl} key={index2} tag={kanal.isHls} tag2={kanal.channelImg}>{kanal.channelName}</td>)}
            </tr>
        );
        totalArray.push(yeniSatir);
        this.setState({listItems:totalArray});
    }
    oynat(event) {
        if(this.state.snakeDivHeight=="0px"){
            this.setState({snakeDivHeight: "350px"});
        }else{
            this.setState({snakeDivHeight: "0px"});
        }

    }
    kanaldegistir(event){
        let kanalAdi = event.target.innerText;
        let kanalLinki = event.target.attributes.value.value;
        let kanalHls = event.target.attributes.tag.value;
        let kanalResim = event.target.attributes.tag2.value;
        if(this.state.suanCaliyor){
            if(this.state.nowHls){
                this.state.nowHls.stopLoad();
                this.state.nowHls.detachMedia();
                this.state.nowHls.destroy();
            }
        }
        this.state.muzikCalar.pause();
        this.state.muzikCalar.src = "";
        this.state.muzikCalar.load();
        if(kanalHls==="0"){
            this.state.muzikCalar.src = kanalLinki;
            this.state.muzikCalar.play();
            this.setState({suanCaliyor: true});
        }else{
            var hls = new window.Hls();
            this.setState({nowHls:hls});
            hls.loadSource(kanalLinki);
            hls.attachMedia(this.state.muzikCalar);
            this.state.muzikCalar.play();
            this.setState({suanCaliyor: true});
        }
        document.getElementById("channelImg").style.backgroundImage = "url("+this.state.resimler[kanalResim]+")";
        /* YENI MEDIA SESSION KODLARI */
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: '𝗩𝗔𝗗𝗜 𝗥𝗔𝗗𝗜𝗢',
                artist: kanalAdi,
                artwork: [
                    {src: this.state.resimler[kanalResim], sizes: '200x200', type: 'image/png'}
                ]
            });
            navigator.mediaSession.setActionHandler('nexttrack', function() {
                var nextButton = event.target.nextElementSibling;
                if(nextButton===null){
                    var parentElem = event.target.parentElement.nextElementSibling;
                    nextButton = parentElem.children[0];
                }
                if(nextButton!=null){
                    nextButton.click();
                }
            });
            navigator.mediaSession.setActionHandler('previoustrack', function() {
                var prevButton = event.target.previousElementSibling;
                if(prevButton===null){
                    var parentElem = event.target.parentElement.previousElementSibling;
                    prevButton = parentElem.children[parentElem.children.length-1];
                }
                if(prevButton!=null){
                    prevButton.click();
                }
            });
        }
    }
    pressedPlay(event){
        this.state.muzikCalar.play();
        this.setState({suanCaliyor: true});
    }
    pressedPause(event){
        this.state.muzikCalar.pause();
        this.setState({suanCaliyor: false});
    }
    pressedMute(event){
        if(this.state.muzikCalar.muted){
            this.state.muzikCalar.muted = false;
        }else{
            this.state.muzikCalar.muted = true;
        }
    }
  render(){
    return(
        <div>
            <audio id="muzikCalar1" src="" key=""></audio>
        <div className="card">
            <div className="glass">
                <div className="glass_top">
                    <div className="glass_topLeft">
                        <div id="channelImg" className="glass_topLeftImg"></div>
                        <div className="glass_topLeftCtrl">
                            <div>
                                <center>
                                    {this.state.suanCaliyor ? <button type="button" className="pauseBtn" onClick={this.pressedPause}></button> : <button type="button" className="playBtn" onClick={this.pressedPlay}></button>}
                                </center>
                            </div>
                            <div>
                                <center>
                                    <button type="button" onClick={this.pressedMute}></button>
                                </center>
                            </div>
                        </div>
                    </div>
                    <div className="glass_topRight">
                        <div className="sure_container">
                            <span className="sure_sa">
                                {Math.floor(this.state.currentSeek/3600)}:
                            </span>
                            <span className="sure_dk">
                                {Math.floor((this.state.currentSeek%3600)/60)<10 ? "0"+Math.floor((this.state.currentSeek%3600)/60):Math.floor((this.state.currentSeek%3600)/60)}:
                            </span>
                            <span className="sure_sn">
                                {Math.floor(this.state.currentSeek%60)<10 ? "0"+Math.floor(this.state.currentSeek%60):Math.floor(this.state.currentSeek%60)}
                            </span>
                        </div>
                        <div className="ses_container">
                            Ses:&nbsp;<input type="range" id="volume-control" min="0" max="100" defaultValue={this.state.sesSeviyesi} step="5" onChange={this.handleChange} />
                        </div>
                        <div className="ses_container">
                            <img src={harlow} alt="..." />
                        </div>
                        <div className="info_container">
                            <div className="gmbtnDiv" onClick={this.oynat} title="?#!@€&">
                                <center><button type="button" className="gameBtn"></button></center>
                            </div>
                            <a href="https://discord.gg/uKuK6FeZhj" rel="noopener noreferrer" target="_blank" title="Discord"><div className="dcbtnDiv">
                                <center><span className="discordBtn"></span></center>
                            </div></a>
                            <div className="infoContentDiv">
                                Vadi Radio v1.0.4
                            </div>
                        </div>
                    </div>
                </div>
                <div className="glass_bottom">
                    <table>
                        <tbody>
                        {this.state.listItems}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="snakeDiv" style={{height:this.state.snakeDivHeight}}>
                <Snake size={350} />
            </div>
        </div>
    </div>
    );
  }
}
export default App;